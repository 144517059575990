<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div style="position: sticky; top: 99px; z-index: 9; background: white">
        <div class="col-md-12" style="background: white">
          <div class="col-md-6 ml-5 mb-10">
            <h6>{{ client_name }}</h6>
            Workstation Label: <strong>{{ label }}</strong>
          </div>
          <div
            class="col-md-6"
            style="margin-top: -4%; margin-bottom: 4%; float: right"
          >
            <button
              ref="kt_update_note"
              type="reset"
              class="btn btn-success mr-2"
              style="height: 36px; float: right"
              @click="showModal"
            >
              Add
            </button>
            <button
              ref="kt_update_note"
              type="reset"
              class="btn btn-danger mr-3"
              style="height: 36px; float: right"
              @click="goback($router.go(-1))"
            >
              <i class="fa fa-arrow-left"></i>
              Go back
            </button>
          </div>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th scope="col">Serial Number</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody v-if="!loadingData && devices.length > 0">
          <tr v-for="(item, index) in devices" :key="index">
            <td>{{ ++index }}</td>

            <td>{{ item.serial_number }}</td>
            <td>
              <button class="btn btn-success btn-sm" @click="edit(item)">
                Edit
              </button>
              <button
                class="btn btn-danger btn-sm ml-2"
                @click="deleteItem(item)"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-if="!loadingData && devices.length < 1">
          <tr>
            <td colspan="8" class="text-center">No record(s) found</td>
          </tr>
        </tbody>
      </table>
      <!-- <button class="btn btn-success" @click="storeUpdate">Update</button> -->
    </div>
    <device
      v-if="ModalShow"
      v-on:closemodal="closeModal"
      :show-modal="ModalShow"
      :device="deviceData"
      :is_edit="is_edit"
    ></device>
  </div>
</template>

<script>
// import moment from "moment";
import {
  CREATE_GROUP_SERVICE,
  GET_API_KEY,
  DELETE_APIKEY_DEVICE
} from "@/core/services/store/actions.type";
import device from "@/pages/backend/apikey/devices/device";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentUser"])
  },
  components: {
    device
  },
  mounted() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(GET_API_KEY, this.$route.params.id).then(res => {
      this.api_key = res.api_key;
      this.label = res.label;
      this.client_name = res.client_name;
      this.devices = res.api_key_devices;
    });
  },
  methods: {
    showModal() {
      this.deviceData = {
        id: 0,
        serial_number: "",
        api_key: this.$route.params.id
      };
      this.ModalShow = true;
      this.is_edit = false;
    },
    closeModal() {
      this.ModalShow = false;
      this.$store.dispatch(GET_API_KEY, this.$route.params.id).then(res => {
        this.api_key = res.api_key;
        this.label = res.label;
        this.client_name = res.client_name;
        this.devices = res.api_key_devices;
      });
    },
    edit(item) {
      this.ModalShow = true;
      this.is_edit = true;
      this.deviceData = {
        ...item
      };
    },
    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store.dispatch(DELETE_APIKEY_DEVICE, item.id).then(() => {
            this.$toastr.s("Device Successfully Deleted ");
            this.$store
              .dispatch(GET_API_KEY, this.$route.params.id)
              .then(res => {
                this.api_key = res.api_key;
                this.label = res.label;
                this.client_name = res.client_name;
                this.devices = res.api_key_devices;
              });
          });
        }
      });
    },
    storeUpdate() {
      let data = {
        id: this.$route.params.id,
        data: this.services
      };
      this.$store
        .dispatch(CREATE_GROUP_SERVICE, data)
        .then(() => {
          this.$toastr.s("Service added successfully");
          this.$store.dispatch(GET_API_KEY, this.$route.params.id).then(res => {
            this.api_key = res.api_key;
            this.label = res.label;
            this.client_name = res.client_name;
            this.devices = res.api_key_devices;
          });
        })
        .catch(() => {
          this.$toastr.e("oops something went wrong !");
        });
    }
  },
  data() {
    return {
      loadingData: false,
      api_key: "",
      label: "",
      client_name: "",
      ModalShow: false,
      devices: [],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Workstation List",
          route: "client.apikey"
        },
        {
          id: 3,
          title: "Devices",
          route: ""
        }
      ]
    };
  }
};
</script>
